import {BaseModel} from '../base/base-model';
import {DatatableData} from '../protocols/datatable-data';
import {SearchListable} from '../protocols/search-listable';
import {Selectable} from '../protocols/selectable';
import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import { CameraSystemTypes } from '../enum/shared/camera-system-type.enum';

export class CameraSystem extends BaseModel implements DatatableData, SearchListable, Selectable {
  id: string;
  name: string;
  systemTypeId: string;
  systemTypeIdToNumber = null;
  status: number;
  statusToBoolean: boolean;
  config: any;
  version: string;
  currentFirmware: string;
  serialNumber: string;
  streamStatus: string;
  errorText: string;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;
  timeStampVal: Date;
  statusUpdated: Date;
  lastStreamEnded: Date;




  static initWithSystems(systems: CameraSystem, cs: string[]): CameraSystem {
    const formObjects = new CameraSystem();
    formObjects.id = systems.id;
    formObjects.name = systems.name;
    formObjects.systemTypeIdToNumber = cs.indexOf(systems.systemTypeId);
    formObjects.currentFirmware=systems.currentFirmware;
    formObjects.serialNumber=systems.serialNumber;
    formObjects.status = systems.status;
    formObjects.statusToBoolean = systems.status === 1;
    formObjects.timeStampVal = systems.timeStampVal;
    formObjects.version = systems.version;
    return formObjects;
  }

  onDeserialize() {
    super.onDeserialize();
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
    return null;
  }

  getSearchListablePrimaryKey(): string {
    return this.name;
  }

  getSearchListableSecondaryKey(): string {
    return '';
  }

  getSearchListableSubTitle(): string {
    return $localize`Camera System`;
  }

  getSearchListableTitle(): string {
    return this.name;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}
