import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {SportType} from '../models/lookup/sport-type';
import { PermissionType } from '../models/lookup/permission-type';
import { ProgramStatusType } from '../models/lookup/program-status-type';
import { CountryType } from '../models/lookup/country-type';
import {CreateSportTypeRequest} from '../models/lookup/requests/create-sport-type-request';
import {ProductionType} from '../models/lookup/production-type';
import {AdvertisementPriorityType} from '../models/lookup/advertisement-priority-type';
import {DepartmentType} from '../models/lookup/department-type';

@Injectable({
  providedIn: 'root',
})
export class LookupApi implements LoggableAPI {
  // Variables
  public serviceName = 'Lookup';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public getPixellotProductionTypes(): Observable<string[]> {
    return this.apiClient.getStringArr(Endpoints.getPixellotProductionTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPixellotProductionTypes', err));
        return throwError(err);
      })
    );
  }

  public getSportTypes(): Observable<SportType[]> {
    return this.apiClient.getArr(SportType, Endpoints.getSportTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSportTypes', err));
        return throwError(err);
      })
    );
  }

  public getPermissionTypes(): Observable<PermissionType[]> {
    return this.apiClient.getArr(PermissionType, Endpoints.getPermissionTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getPermissionTypes', err));
        return throwError(err);
      })
    );
  }

  public getProgramStatusTypes(): Observable<ProgramStatusType[]> {
    return this.apiClient.getArr(ProgramStatusType, Endpoints.getProgramStatusTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getProgramStatusTypes', err));
        return throwError(err);
      })
    );
  }

  public getProductionTypes(): Observable<ProductionType[]> {
    return this.apiClient.getArr(ProductionType, Endpoints.getProductionTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getProductionTypes', err));
        return throwError(err);
      })
    );
  }

  public getAdvertisementPriorityTypes(): Observable<AdvertisementPriorityType[]> {
    return this.apiClient.getArr(AdvertisementPriorityType, Endpoints.getAdvertisementPriorityTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getAdvertisementPriorityTypes', err));
        return throwError(err);
      })
    );
  }

  public getCountryTypes(): Observable<CountryType[]> {
    return this.apiClient.getArr(CountryType, Endpoints.getCountryTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCountryTypes', err));
        return throwError(err);
      })
    );
  }

  public getDepartmentTypes(): Observable<DepartmentType[]> {
    return this.apiClient.getArr(DepartmentType, Endpoints.getDepartment()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getDepartmentTypes', err));
        return throwError(err);
      })
    );
  }

  public getCameraSystemTypes(): Observable<string[]> {
    return this.apiClient.getStringArr(Endpoints.getCameraSystemTypes()).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getCameraSystemTypes', err));
        return throwError(err);
      })
    );
  }

  public createSportType(req: CreateSportTypeRequest): Observable<SportType> {
    return this.apiClient.postObj<SportType>(SportType, Endpoints.createSportType(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSportType', err));
        return throwError(err);
      })
    );
  }

  public updateSportType(req: CreateSportTypeRequest, id: string): Observable<SportType> {
    return this.apiClient.putObj<SportType>(SportType, Endpoints.updateSportType(id), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateSportType', err));
        return throwError(err);
      })
    );
  }
}
