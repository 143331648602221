<app-content-container [narrow]="true">
  <div class="py-4 h-100 position-relative min-height-20">
    <app-form-group
      *ngIf="formItems?.length > 0 && !!formObject"
      [hidden]="viewModel.loadingOpts?.isLoading"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="PermissionManagerService.isGranted(Types.AdminSections.Events_Edit_Event_Details_Edit) ? formSubmitted($event) : null">
      <div class="d-block w-100">
        <div class="event-photo-container" (click)="showEditPhotoModal()">
          <div class="event-photo mr-3">
            <img [src]="formObject?.imageToUpload" alt=""
                 *ngIf="formObject.imageToUpload">
            <img [src]="(formObject?.event?.imgSrc$|async) || ''" alt=""
                 *ngIf="formObject.existingImageId && !formObject.imageToUpload && !formObject.deleteImageId">
          </div>
          <div class="photo-text">
            <ng-container
              *ngIf="!(formObject.imageToUpload || formObject.existingImageId)"
              i18n>Add a Event Logo
            </ng-container>
            <ng-container
              *ngIf="formObject.imageToUpload || formObject.existingImageId"
              i18n>Change Event Logo
            </ng-container>
          </div>
        </div>
        <hr>
        <div class="label-md mb-4" i18n>Game Card Image</div>
        <app-upload-asset
        *ngIf="!formObject.bannerImageToUpload && !formObject.existingBannerImageId"
          [allowTypes]="['image']"
          [maxAssets]="1"
          [id]="1"
          [parentHandler]="this">
        </app-upload-asset>
        <div class="banner-image-container" *ngIf="formObject.bannerImageToUpload || formObject.existingBannerImageId">
          <div class="banner-image">
            <img *ngIf="formObject.existingBannerImageId" [src]="(formObject.event?.bannerSrc$|async)" alt="">
           <img  *ngIf="!formObject.existingBannerImageId" [src]="formObject.bannerImageToUpload" alt="">
          </div>
           <div class="text-link-button my-4" (click)="removeBannerImageClicked()" i18n>Remove Card Image</div>
        </div>
      </div>
    </app-form-group>
    <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                 [options]="viewModel.loadingOpts">
    </app-loading>
  </div>
</app-content-container>
