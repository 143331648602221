import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {BehaviorSubject, Observable} from 'rxjs';
import {CameraSystem} from '../../../models/resources/camera-system';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {ToastService} from '../../../services/toast-service';
import { CameraStream } from 'src/app/models/resources/camera-stream';
import { streamStatus } from 'src/app/models/enum/dto/stream-status-enum';
import { DateUtils } from 'src/app/utils/date-utils';
import { Stream } from 'stream';
import { LookupDomainModel } from 'src/app/domainModels/lookup-domain-model';

@Injectable()
export class CameraSystemViewModal extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  refreshSystemSubject$ = new BehaviorSubject<void>(null);
  addNewSystem$ = this.activatedRoute.data.pipe(map(data => data.addSystem as boolean));
  systemId$ = this.activatedRoute.params.pipe(map(params => params.cameraSystemId));
  system$: Observable<any> = this.systemId$.pipe(
    switchMap(systemId => this.getCameraSystemById(systemId))
  );
  cameraSystemTypes$=this.lookupDomainModel.cameraSystemTypes;

  constructor(
    private domainModel: ResourceDomainModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private lookupDomainModel: LookupDomainModel,

  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

saveCameraSystem(formObject: CameraSystem) {
    this.domainModel.saveCameraSystem(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Camera System`))
      .subscribe(() => {
        if (!!formObject.id) {
          this.toastService.publishSuccessMessage($localize`Camera System Updated`, null);
          this.refreshSystemSubject$.next();
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        } else {
          this.toastService.publishSuccessMessage($localize`Camera System Created`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        this.toastService.publishError(error);
      });
    //this.updateCameraSystemTypeConfigurations(formObject.id, formObject.config).subscribe();
  }

  getCameraSystems(): Observable<CameraSystem[]> {
    return this.domainModel.getCameraSystems();
  };

  getCameraSystemById(cameraSystemId: string): Observable<CameraSystem> {
    return this.domainModel.getCameraSystemById(cameraSystemId);
  }

  getCameraSystemTypeConfigurations(cameraSystemTypeId: string): Observable<CameraSystem> {
    return this.domainModel.getCameraSystemTypesConfigurations(cameraSystemTypeId);
  }

  rowClicked(data: DatatableData) {
    const cameraSystem = data as CameraSystem;
    this.router.navigate([`./${cameraSystem.id}`], {relativeTo: this.activatedRoute,
        queryParams: { forceUpdate: true }
      }).then();
}
  addNewSystem() {
    this.router.navigate([`./add`], {relativeTo: this.activatedRoute}).then();
  }

  updateCameraSystemTypeConfigurations(cameraSystemTypeId: string, req: any): Observable<CameraSystem> {
    return this.domainModel.updateCameraSystemTypeConfigurations(cameraSystemTypeId, req);
  }
  getCameraStreams(isLive: boolean): Observable<CameraStream[]>{
    return this.domainModel.getCameraStreamDetails(isLive);
  }

  getCameraStatus(camera: CameraSystem): string{
    switch(camera.streamStatus){
      case streamStatus.Offline:
        return streamStatus.Offline + ': '+ DateUtils.formatDateTimeToString(camera.statusUpdated);
      case streamStatus.Missing:
        return streamStatus.Missing + ': '+ DateUtils.formatDateTimeToString(camera.statusUpdated);
      case streamStatus.Failed:
        return streamStatus.Failed + ': '+ DateUtils.formatDateTimeToString(camera.statusUpdated);
      case streamStatus.Dropped:
        return streamStatus.Dropped + ': '+ DateUtils.formatDateTimeToString(camera.lastStreamEnded);
      case streamStatus.Error:
        return streamStatus.Error+ ': '+camera.errorText;
      case streamStatus.Defunct:
        return streamStatus.Defunct+': '+
        DateUtils.formatDateTimeToString(camera.statusUpdated)
        +': '+camera.errorText;
      default:
        return camera.streamStatus;

    }
  }

  getStreamStatus(stream: CameraStream): string{
    switch(stream.status){
      case streamStatus.Offline:
        return streamStatus.Offline + ': '+ DateUtils.formatDateTimeToString(stream.lastUpdatedUtc);
      case streamStatus.Missing:
        return streamStatus.Missing + ': '+ DateUtils.formatDateTimeToString(stream.lastUpdatedUtc);
      case streamStatus.Failed:
        return streamStatus.Failed + ': '+ DateUtils.formatDateTimeToString(stream.lastUpdatedUtc);
      case streamStatus.Dropped:
        return streamStatus.Dropped + ': '+ DateUtils.formatDateTimeToString(stream.lastStreamEnded);
      case streamStatus.Error:
        return streamStatus.Error+ ': '+stream.errorText;
      case streamStatus.Defunct:
        return streamStatus.Defunct+': '+
        DateUtils.formatDateTimeToString(stream.lastUpdatedUtc)
        +': '+stream.errorText;
      default:
        return stream.status;
    }
}

}
