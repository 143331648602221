import {BaseModel} from '../base/base-model';
import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Cardable} from '../protocols/cardable';
import {SafeResourceUrl} from '@angular/platform-browser';
import {DateUtils} from '../../utils/date-utils';
import {EMPTY, Observable} from 'rxjs';
import {HydratedProgram} from './hydrated-program';

export class Program extends BaseModel implements Deserializable, Cardable {
  public id: string;
  public name: string = null;
  public description: string = null;
  public shortDescription: string = null;
  public productionTypeId: number = null;
  public productionType: string;
  public playCount: number = null;
  public programStatusId: number = null;
  public programStatus: string;
  public venueStreamId: number = null;
  public venueStream: string;
  public homeTeamId: number = null;
  public homeTeam: string;
  public visitingTeamId: number = null;
  public visitingTeam: string;
  public durationInMinutes: number = null;
  public startDateUtc: Date;
  public subscriptionPlanId: string = null;
  public playbackStreamUrl: string;
  public amazonIngestStreamUrl: string;
  public amazonIngestStreamKey: string;
  public pixellotHDStreamUrl: string;
  public pixellotPANOStreamUrl: string;
  public homeTeamLocation: string;
  public visitingTeamLocation: string;
  public pixellotEventStartTimeOffsetInMinutes: number = null;
  public freeToWatch: boolean = false;
  public isEnabledForAds: boolean = false;
  public isPixellotSystem: boolean;
  public channelArn: string;
  public permanentIvsUrl: string;


  public imgSrc$: Observable<SafeResourceUrl | string>;

  public venueId: number = null;
  public venue: string;
  public leagueId: number = null;
  public league: string;
  public eventId: number = null;
  public event: string;
  public isPrivateAccess: boolean;
  public programMp4StatusId: number;
  public mP4Url: string;
  public timeStampVal: Date;

  static sortProgramsByStartDate(programs: HydratedProgram[], ascending: boolean): HydratedProgram[] {
    const sortOrderMultiplier = ascending ? -1 : 1;
    return programs.sort((a: HydratedProgram, b: HydratedProgram) => {
      return DateUtils.dateIsBefore(b.startDateUtc, a.startDateUtc) * sortOrderMultiplier;
    });
  }

  onDeserialize() {
    super.onDeserialize();
    this.startDateUtc = DeserializeHelper.deserializeToDate(this.startDateUtc);
    this.description = this.description ?? null;
    this.shortDescription = this.shortDescription ?? null;
    this.homeTeamId = this.homeTeamId ?? null;
    this.visitingTeamId = this.visitingTeamId ?? null;
  }

  getCardCategory(): string {
    return this.league ?? this.event ?? this.venue;
  }

  getCardImageSrc(): Observable<SafeResourceUrl | string> {
    return this.imgSrc$ ?? EMPTY;
  }

  getCardMetadata(): string {
    return DateUtils.formatDateTimeToReadableString(this.startDateUtc);
  }

  getCardDescription(): string {
    return null;
  }

  getCardShortDescription(): string {
    return this.shortDescription;
  }

  getCardTitle(): string {
    if (this.visitingTeam?.length > 0 && this.homeTeam?.length > 0) {
      return $localize`${this.homeTeam} vs. ${this.visitingTeam}`;
    }
    if (this.venueStream?.length > 0) {
      return this.venueStream;
    }
    return '';
  }

  getCardAwayTeamId(): number {
    return this.visitingTeamId;
  }

  getCardHomeTeamId(): number {
    return this.homeTeamId;
  }

  getGoogleAnalyticsEventTitle(): string {
    return `${this.getCardTitle()} - ${DateUtils.formatDateWithYearMonthDay(this.startDateUtc)} - ${this.id}`;
  }
}




